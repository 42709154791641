import React, { useContext } from "react";
import {
  StyledPagesPreview,
  StyledPagesPreviewPage,
  StyledPagesPreviewPageMedia,
  StyledPagesPreviewPageTitle,
  StyledPagesPreviewPageSubtitle,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Icon, { icons } from "../Icon/Icon";
import { Button } from "@3beehivetech/components";
import Scroller from "../Scroller/Scroller";
import { DataContext } from "../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Link from "../Link/Link";



export default ({ pages, allImages }) => {
  const { i18nLink } = useContext(DataContext);

  return (
    <StyledGridRow isFullMobile>
      <Scroller>
        <StyledPagesPreview>
          {pages.map((page, index) => {
            const imageData = allImages.find(e => e.path === page.image);
            const buttonChildren =
              <>
                {page?.cta?.label}
              </>
            return (
              <StyledPagesPreviewPage key={index}>
                <div style="position: absolute; bottom: 100px; left: 30px; z-index: 2;">
                  <StyledPagesPreviewPageTitle dangerouslySetInnerHTML={{ __html: page.title }} />
                  <StyledPagesPreviewPageSubtitle dangerouslySetInnerHTML={{ __html: page.subtitle }} />
                </div>
                <Link to={page?.cta?.url ? i18nLink(`${page.cta.url}`) : null}>
                  <Button style="z-index:2; position: absolute; left: 30px; bottom: 30px; z-index: 2; height: 40px;" children={buttonChildren} />
                </Link>
                <StyledPagesPreviewPageMedia>
                  <GatsbyImage style={{ height: "100%" }} image={getImage(imageData)} alt={imageData.alt} />
                </StyledPagesPreviewPageMedia>
                <div style="z-index:1; position: absolute; top:0px; left:0px; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 4.07%, rgba(0, 0, 0, 0.5) 76.03%, rgba(0, 0, 0, 0.1) 99.33%); width: 100%; height: 100%;" />
              </StyledPagesPreviewPage>
            );
          },
          )}
        </StyledPagesPreview>
      </Scroller>
    </StyledGridRow>
  );
};

/*
 {page.cta.label}
                    <Icon icon={icons.arrowRight} />
                  </Button>
                  */